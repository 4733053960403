.ant-form-inline .ant-form-item {
  margin-bottom: 16px;
}
.features-collapsable {
    margin-right: 24px;
    margin-bottom: 24px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 5px 12px;
}