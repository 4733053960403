.two-hour {
  color: var(--two-hour-color);
}
.two-weeks {
  color: var(--two-weeks-color);
}

.after-two-weeks {
  color: var(--after-two-weeks-color);
}
