@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

.site-layout-content {
  min-height: 600px;
  padding: 24px;
  background: #fff;
}
.logo {
  float: left;
  width: 120px;
  height: 64px;
  font-family: 'Oswald', sans-serif;
  color: #fff;
  font-size: 1.3em;
  margin-right: 50px;
}
.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}
[data-theme='dark'] .site-layout-content {
  background: #141414;
}

.admin-page-sign-out {
  float: right;
  color: white;
  cursor: pointer;
}
